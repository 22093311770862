import { Spinner } from "../spinner/spinner";
import './full-screen-loader.scss';

interface IFullscreenLoaderProps {
  text?: string;
}

export function FullScreenLoader(props: IFullscreenLoaderProps) {
  return (
    <div className="full-screen-loader">
      {props.text ? <span>{props.text}</span> : null}
      <Spinner></Spinner>
    </div>
  )
}