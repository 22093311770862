import './routes.scss';
import { forwardRef } from "react";
import { TimelineItem } from "@o4c/plugin-components";
import { IStop } from "@project/trip.interface";
import { useTranslation } from "react-i18next";
import { RouteCard } from "./route-card/route-card";
import dayjs from 'dayjs';

interface IRoutesProps {
  stops: IStop[];
}

interface IDay {
  date: string;
  stops: IStop[];
}

export const Routes = forwardRef<HTMLDivElement, IRoutesProps>((props, ref) => {
  const { t } = useTranslation()

  const convertStopsToDays = (stops: IStop[]): IDay[] => {
    const days: IDay[] = [];
    stops.forEach((stop: IStop) => {
      let dayIndex = days.findIndex(day => day.date === dayjs(stop.startTime).format('YYYY-MM-DD'))
      if (dayIndex > -1) {
        days[dayIndex].stops.push(stop)
      }
      else {
        days.push({
          date: dayjs(stop.startTime).format('YYYY-MM-DD'),
          stops: [stop]
        })
      }
    })
    return days;
  }

  const days: IDay[] = convertStopsToDays(props.stops)

  return (
    <>
      {

        days.map((day: IDay, index: number) => {
          return <TimelineItem key={index}>
            <span ref={ref} className="route-header" id="route-start">{dayjs(day.date).format(t("dateFormatting.day"))}</span>
            {day.stops.map((stop: IStop, index: number) => {
              return <RouteCard stop={stop} key={index} />
            })
            }
          </TimelineItem>
        })
      }
    </>
  )
})