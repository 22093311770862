import { Button, Icon } from '@o4c/plugin-components';
import { useTranslation } from 'react-i18next';
import './fuel-guidance.scss';
import { FuelStop } from './fuel-stop/fuel-stop';
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import * as L from 'leaflet'
import 'leaflet/dist/leaflet.css';
import { IFuelStop } from '@project/fuel-stop.interface';

interface IFuelGuidanceProps {
  isVisible: boolean;
  onVisibleChange: Function;
  stops: IFuelStop[];
}

export const FuelGuidance = (props: IFuelGuidanceProps) => {
  const { t } = useTranslation()
  function toggle() {
    props.onVisibleChange(!props.isVisible);
  }

  const customMarker = (index: number) => {
    const marker = new (L.DivIcon as any)({
      className: "marker",
      iconSize: [32, 48],
      iconAnchor: [16, 48],
      html: `<div class="number">${index}</div>`
    })
    return marker
  };

  const bounds = props.stops.length ? new L.LatLngBounds(props.stops.map((stop: IFuelStop) => [stop.latitude, stop.longitude])).pad(0.2) : undefined;

  return <div className={`slide-up-panel-container ${props.isVisible && props.stops.length ? 'visible' : ''}`}>
    <Button
      className={`slide-up-panel-trigger ${props.stops.length ? 'visible' : 'hidden'}`}
      display="inline"
      floating
      onClick={toggle}
    >
      <Icon name="fuel" />
    </Button>
    <div className="slide-up-panel">
      <div className="header">
        <Icon name="fuel" leading />
        <span>{t('trip.fuelGuidance')}</span>
        <Icon name="close" onClick={toggle} />
      </div>
      <div className="map">
        <MapContainer bounds={bounds} scrollWheelZoom={true}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {
            props.stops.map((stop, index) => <Marker key={index} icon={customMarker(index + 1)} position={[stop.latitude, stop.longitude]} />)
          }
        </MapContainer>
      </div>
      <div className="container">
        {props.stops.map((stop: any, index: number) => <FuelStop key={index} stop={stop} number={index + 1} />)}
      </div>
    </div>
  </div >;
}