import { Card, CardMain } from '@o4c/plugin-components';
import { IFuelStop } from '@project/fuel-stop.interface';
import './fuel-stop.scss';

interface IFuelStopProps {
  stop: IFuelStop;
  number: number;
}

export const FuelStop = (props: IFuelStopProps) => {

  return <Card>
    <CardMain>
      <div className="info">
        <div className="number">
          {props.number}
        </div>
        <div className="codes">
          <span className="label">{props.stop.comdataNum} - {props.stop.vendorName}</span><br />
          <span>{props.stop.code}</span>
        </div>
      </div>
      <span className="address">{props.stop.street}, {props.stop.city}, {props.stop.state} {props.stop.zip}</span>
    </CardMain>
  </Card>;
}