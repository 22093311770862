import './App.css';
import { Route, Switch, useLocation } from 'react-router-dom';
import { TripViewer } from './views/trip/trip';
import { GlobalTheme } from '@o4c/plugin-components';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { themes } from './themes/themes';
import { Errors } from './shared/components/errors/errors';
import { FullScreenLoader } from './shared/components/full-screen-loader/full-screen-loader';

function App() {
  const [magazineConfig, setMagazineConfig] = useState<any>()
  const [error, setError] = useState<boolean>(false)
  let location = useLocation();
  let query = new URLSearchParams(location.search);
  const token: string = query.get("token") || "";
  const magazineId: string = query.get("magazine") || "";

  useEffect(() => {
    if (!magazineId || !token) {
      return setError(true)
    }
    const fetchData = async () => {
      const url = `/api/magazines/client-config/${magazineId}`;
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
      };
      await axios(url, { headers: headers }).then((result) => {
        setMagazineConfig(result.data);
      }).catch((error) => {
        setError(true)
      });
    };
    fetchData();
  }, [])

  return <>
    <div className="app">
      {error ? <Errors /> :
        magazineConfig ?
          <>
            <GlobalTheme overrides={themes[magazineConfig.theme.themeId ? magazineConfig.theme.themeId : "ortec"]} />
            <Switch>
              <Route path="/">
                <TripViewer />
              </Route>
            </Switch>
          </>
          : <FullScreenLoader />}

    </div>

  </>
}

export default App;
