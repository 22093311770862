import './trip.scss';
import axios from 'axios';
import dayjs from "dayjs";
import { Timeline, TimelineItem } from "@o4c/plugin-components";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Metadata } from "./metadata/metadata";
import { Routes } from "./routes/routes";
import { Comments } from "./comments/comments";
import { FullScreenLoader } from "../../shared/components/full-screen-loader/full-screen-loader";
import { Errors } from "../../shared/components/errors/errors";
import { ITrip } from "@project/trip.interface"
import { Header } from './header/header';
import { Nav } from './nav/nav';
import { useScrollHandler } from '../../hooks/use-scroll-handler';
import { NoTrips } from '../no-trips/no-trips';
import { FuelGuidance } from './fuel-guidance/fuel-guidance';
import { IFuelStop } from "@project/fuel-stop.interface"

export const TripViewer: FunctionComponent = () => {
  const { t } = useTranslation();
  const [trips, setTrips] = useState<ITrip[]>();
  const [activeTrip, setActiveTrip] = useState<ITrip>();
  const [fuelStops, setFuelStops] = useState<IFuelStop[]>();
  const [isContextMenuVisible, setIsContextMenuVisible] = useState<boolean>(false);
  const [isFuelGuidanceVisible, setIsFuelGuidanceVisible] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false)
  const { stickyHeader, routeStartRef, routeEndRef, scrollToEndRoute, scrollToStartRoute } = useScrollHandler();
  let location = useLocation();
  let query = new URLSearchParams(location.search);
  const token: string = query.get("token") || ""
  const today: string = dayjs().format("YYYY-MM-DD");

  useEffect(() => {
    const fetchData = async () => {
      const url = `/api/trips/${today}`;
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
      };
      await axios(url, { headers: headers }).then((result) => {
        setTrips(result.data);
        if (result.data[0]) {
          setActiveTrip(result.data[0])
        }
      }).catch((error) => {
        setError(true)
      });
    };
    fetchData();
  }, [])

  useEffect(() => {
    if (activeTrip) {
      const fetchData = async () => {
        const url = `/api/fuel-guidance/${activeTrip.tripId}`;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': token
        };
        await axios(url, { headers: headers }).then((result) => {
          if (!result.data.length) {
            setFuelStops([]);
          } else {
            setFuelStops(result.data);
          }
        }).catch((error) => {
          setError(true)
        });
      };
      fetchData();
    }
  }, [activeTrip])

  const selectTrip = (trip: ITrip) => {
    setActiveTrip(trip)
    setIsContextMenuVisible(false)
  }

  return (
    <>
      {
        error ? <Errors /> :
          trips && fuelStops && activeTrip ?
            <>
              <Header
                date={dayjs(activeTrip.startTime).format(t('dateFormatting.day'))}
                title={t('trip.start')}
                location={activeTrip.startingPoint}
                onClick={scrollToStartRoute}
                optionalClass={`${stickyHeader === "top" ? "sticky-top" : ""}`}
              />
              <Timeline>
                <TimelineItem color="primary">
                  <Metadata trip={activeTrip} />
                  <Comments comment={activeTrip.comment} />
                </TimelineItem>
                <Routes ref={routeStartRef} stops={activeTrip.stops} />
              </Timeline>
              <Header
                date={dayjs(activeTrip.endTime).format(t('dateFormatting.day'))}
                title={t('trip.end')}
                location={activeTrip.finalDestination}
                onClick={scrollToEndRoute}
                optionalClass={`${stickyHeader === "bottom" ? "sticky-bottom" : ""}`}
              />
              <div ref={routeEndRef} />
              {trips.length > 1 ? <Nav
                isContextMenuVisible={isContextMenuVisible}
                setIsContextMenuVisible={setIsContextMenuVisible}
                trips={trips} activeTrip={activeTrip}
                selectTrip={selectTrip} />
                : null}
              <FuelGuidance stops={fuelStops} isVisible={isFuelGuidanceVisible} onVisibleChange={setIsFuelGuidanceVisible} />
            </>
            : trips && !activeTrip ?
              <NoTrips />
              : <FullScreenLoader />
      }
    </>
  )
}