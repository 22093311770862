import { useTranslation } from 'react-i18next';
import './errors.scss';

export function Errors() {
  const { t } = useTranslation();

  return <>

    <div className="errors-container">
      <div className="o4c card errors">
        <div className="header">
          <div><i className="icon-alert leading" /> {t('errors.header')}</div>
        </div>
        <div className="main">
          <p><strong>{t('errors.title')}</strong></p>
          <p>{t('errors.text')}</p>
        </div>
      </div>
    </div>

  </>
}