import './route-card.scss';
import dayjs from "dayjs";
import { Card, CardMain, Icon } from "@o4c/plugin-components";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IStop } from "@project/trip.interface";

interface IRouteCardProps {
  stop: IStop;
}

export const RouteCard: FunctionComponent<IRouteCardProps> = (props) => {
  const { t } = useTranslation()

  const stopIcon = (stopType: string) => {
    if (stopType === 'pickup') return 'pick-up'
    if (stopType === 'deliver') return 'delivery'
    if (stopType === 'resource_shift_decouple') return 'decouple'
    return ''
  }

  return (
    <Card className="stop">
      <CardMain>
        <div className="overview-stop">
          <div className="header">
            <div className="times">
              <span>{dayjs(props.stop.startTime).format('HH:mm')}</span>
              <span>{dayjs(props.stop.endTime).format('HH:mm')}</span>
            </div>

            <div className="details">
              <span className="stop-id">
                {props.stop.storeCode}
              </span>
              <span className="name">
                {props.stop.name}
              </span>
              <span className="meta">
                {props.stop.address}
              </span>
            </div>
          </div>
        </div>
        {props.stop.comment ?
          <>
            <div className="comment">
              <i className="icon-message leading" />
              <span className="caption-text">{props.stop.comment}</span>
            </div>
          </>
          : null}
      </CardMain>
      <CardMain className="to-number bg-light">
        <span className="label">{t("trip.toNumber")}</span>{props.stop.toNumber}
      </CardMain>
      <CardMain className="card-footer">
        <div className="icon-footer">
          <Icon
            name={`${stopIcon(props.stop.stopType)}`}
            size="medium"
          />
        </div>
        {props.stop.stopType === "decouple" ?
          <div>
            <span className="label">{t("trip.decoupleTrailer")}</span>
          </div>
          :
          <>
            <div>
              <span className="label">{t("trip.quantity")}</span>
              <span className="caption-text">{props.stop.orderQuantity ? props.stop.orderQuantity : "-"}</span>
            </div>
            <div>
              <span className="label">{t("trip.weight")}</span>
              <span className="caption-text">{props.stop.orderWeight ? props.stop.orderWeight : "-"} lb</span>
            </div>
          </>
        }
      </CardMain>
    </Card >

  )
}