export const themes: any = {
  'ortec': {
    metadata: 'rgba(var(--primary-r), var(--primary-g), var(--primary-b), 1)',
    onMetadata: 'rgba(var(--white-r), var(--white-g), var(--white-b), 1)',
    metadataCenter: 'rgba(0, 68, 107, 1)',
    onMetadataCenter: 'rgba(var(--white-r), var(--white-g), var(--white-b), 1)',
    onMetadataCenterLabel: 'rgba(102, 170, 209, 1)',
    primaryBorder: 'rgba(var(--primary-r), var(--primary-g), var(--primary-b), 1)',
  },
  'dhl': {
    primaryR: 212,
    primaryG: 5,
    primaryB: 17,
    primary: 'rgba(var(--primary-r), var(--primary-g), var(--primary-b), 1)',
    accentR: 255,
    accentG: 204,
    accentB: 0,
    accent: 'rgba(var(--accent-r), var(--accent-g), var(--accent-b), 1)',
    onAccentR: 0,
    onAccentG: 0,
    onAccentB: 0,
    onAccent: 'rgba(var(--on-accent-r), var(--on-accent-g), var(--on-accent-b), 1)',
    metadataR: 255,
    metadataG: 204,
    metadataB: 0,
    metadata: 'rgba(var(--metadata-r), var(--metadata-g), var(--metadata-b), 1)',
    onMetadata: 'rgba(0, 0, 0, 1)',
    metadataCenterR: 212,
    metadataCenterG: 5,
    metadataCenterB: 17,
    metadataCenter: 'rgba(var(--metadata-center-r), var(--metadata-center-g), var(--metadata-center-b), 1)',
    onMetadataCenter: 'rgba(255, 255, 255, 1)',
    onMetadataCenterLabel: 'rgba(255, 255, 255, 1)',
    primaryBorder: 'rgba(150, 3, 12, 1)'
  }
}
