// Import DayJS
import dayjs from 'dayjs';

// Import plugins
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
// import IsoWeek from 'dayjs/plugin/isoWeek'
// import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
// import Utc from 'dayjs/plugin/utc'
// import Timezone from 'dayjs/plugin/timezone'


// Import locales
import 'dayjs/locale/en';


// Extend DayJS with imported plugins
dayjs.extend(AdvancedFormat)
// dayjs.extend(IsoWeek)
// dayjs.extend(isSameOrBefore)
// dayjs.extend(Utc)
// dayjs.extend(Timezone)

// Export empty module
const DayJSConfig = {};
export default DayJSConfig;