import { Icon } from '@o4c/plugin-components';
import { useTranslation } from 'react-i18next';
import './no-trips.scss';

export const NoTrips = () => {
  const { t } = useTranslation();

  return <>
    <div className="no-trips-container">
      <div className="icon-wrapper">
        <Icon name='no-location' />
      </div>
      <h2>{t('trip.noTrips')}</h2>
    </div>
  </>
}