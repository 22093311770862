import { MutableRefObject, useLayoutEffect, useRef, useState } from "react";

export const useScrollHandler = () => {
  const [stickyHeader, setStickyHeader] = useState<string | null>()
  const routeStartRef = useRef<null | HTMLDivElement>(null)
  const routeEndRef = useRef<null | HTMLDivElement>(null)
  const headerHeight = 95;
  const padding = 16;

  const getPositionFromRef = (ref: MutableRefObject<HTMLDivElement | null>, offset?: number) => {
    const position = ((ref?.current?.getBoundingClientRect().top || 0) + window.scrollY) + (offset ? offset : 0)
    return position
  }
  const scrollTo = (position: number) => {
    window.scrollTo({ top: position, behavior: 'smooth' });
  }

  const scrollToStartRoute = () => {
    scrollTo(0)
  }
  const scrollToEndRoute = () => {
    scrollTo(getPositionFromRef(routeEndRef))
  }

  useLayoutEffect(() => {
    let oldPosition = 0;
    const handleScroll = () => {
      let newPosition = window.scrollY;
      if (newPosition === getPositionFromRef(routeStartRef, -padding)) {
        setTimeout(() => setStickyHeader("bottom"), 100)
      }
      else if (newPosition === 0 || newPosition + window.innerHeight === getPositionFromRef(routeEndRef)) {
        setStickyHeader(null)
      }
      else if (newPosition > oldPosition && newPosition > headerHeight) {
        setStickyHeader("bottom")
      }
      else if (newPosition < oldPosition && (newPosition + window.innerHeight) < (getPositionFromRef(routeEndRef) - headerHeight)) {
        setStickyHeader("top")
      }
      oldPosition = window.scrollY
    }
    window.addEventListener("scroll", handleScroll);
    return () =>
      window.removeEventListener("scroll", handleScroll);
  }, [])

  return { stickyHeader, routeStartRef, routeEndRef, scrollToEndRoute, scrollToStartRoute }
}