import './metadata.scss';
import dayjs from "dayjs";
import { Card, CardMain } from "@o4c/plugin-components";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { ITrip } from "@project/trip.interface";

interface IMetaDataProps {
  trip: ITrip;
}

export const Metadata: FunctionComponent<IMetaDataProps> = (props) => {
  const { t } = useTranslation();

  const formatHours = (hours: number) => {
    return `${Math.floor(hours)}h ${Math.ceil((hours % Math.floor(hours)) * 60)}min`
  }

  return (
    <>
      <div className="metadata">
        <h3 className="trip-id">{props.trip.tripId}</h3>
        <Card className="trip-card">
          <CardMain className="flex-50">
            <div>
              <span className="label">{t("trip.routeId")}</span>
              <p>{props.trip.routeId}</p>
            </div>
            <div>
              <span className="label">{t("trip.shortCode")}</span>
              <p>{props.trip.shortCode}</p>
            </div>
          </CardMain>
          <CardMain className="middle">
            <div>
              <span className="label light">{t("trip.startingTime")}</span>
              <h1>{dayjs(props.trip.startTime).format("HH:mm")}</h1>
            </div>
            <div>
              <span className="label light right">{t("trip.duration")}</span>
              <h3>{formatHours(dayjs(props.trip.endTime).diff(dayjs(props.trip.startTime), 'hour', true))}</h3>
            </div>
          </CardMain>
          <CardMain className="flex-50">
            <div>
              <span className="label">{t("trip.unit")}</span>
              <p>{props.trip?.unit ?? "-"}</p>
            </div>
            <div>
              <span className="label">{t("trip.trailerUnit")}</span>
              <p>{props.trip?.trailerUnit ?? "-"}</p>
            </div>
          </CardMain>
          <div className="divider bg-black o10"></div>
          <CardMain>
            <div>
              <span className="label">{t("trip.stops")}</span>
              <p>{props.trip?.stops?.length ?? "-"}</p>
            </div>
            <div>
              <span className="label">{t("trip.distance")}</span>
              <p>{props.trip.distanceMiles ?? "-"} mi</p>
            </div>
            <div>
              <span className="label">{t("trip.weight")}</span>
              <p>{props.trip.weightLbs ?? "-"} lb</p>
            </div>
          </CardMain>
        </Card>
      </div>
    </>
  )
}