import { Button } from '@o4c/plugin-components';
import './context-menu.scss';

export interface IContextMenuProps {
  title: string;
  children: any;
  isVisible: boolean;
  onVisibleChange: Function;
}

export function ContextMenu(props: IContextMenuProps) {
  function toggle() {
    props.onVisibleChange(!props.isVisible);
  }

  return <div className={`context-menu-container ${props.isVisible ? 'visible' : ''}`}>
    <Button
      className="context-menu-trigger"
      display="inline"
      floating
      color={!props.isVisible ? 'accent' : undefined}
      onClick={toggle}
    >
      <i className={props.isVisible ? "icon-plus" : "icon-list"} />
    </Button>
    <div className="context-menu-slider">
      <div className={`context-menu`}>
        <div className="context-menu-header">{props.title}</div>
        <div className="context-menu-main">
          {props.children}
        </div>
      </div>
      <button onClick={toggle} className="context-menu-overlay"></button>
    </div>
  </div>;
}