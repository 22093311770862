import './comments.scss';
import { FunctionComponent } from "react";

interface ICommentsProps {
  comment: string;
}

export const Comments: FunctionComponent<ICommentsProps> = (props) => {
  return (
    <>
      {props.comment ?
        <div className="comment">
          <i className="icon-notes leading" />
          <span className="caption-text">{props.comment}</span>
        </div>
        : null}
    </>
  )
}