import './nav.scss';
import dayjs from "dayjs";
import { Card, CardHeader, CardMain, Icon, Pill } from "@o4c/plugin-components";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { ITrip } from "@project/trip.interface"
import { ContextMenu } from '../../../shared/components/context-menu/context-menu';

interface INavProps {
  trips: ITrip[];
  activeTrip: ITrip;
  selectTrip: Function;
  isContextMenuVisible: boolean;
  setIsContextMenuVisible: Function;

}
export const Nav: FunctionComponent<INavProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <ContextMenu
        title={t('trip.yourTrips')}
        isVisible={props.isContextMenuVisible}
        onVisibleChange={props.setIsContextMenuVisible}>
        {props.trips.map((trip: ITrip) => {
          return <Card
            key={trip.tripId}
            className={`trip-nav-card ${props.activeTrip.tripId === trip.tripId ? "active" : ""}`}
            onClick={() => props.selectTrip(trip)}>
            <CardHeader>
              <div className="trip-id">
                <span className="body-text"> {trip.tripId}</span>
              </div>
              <Icon name="arrow-right" />
            </CardHeader>
            <CardMain>
              {dayjs(dayjs()).isAfter(dayjs(trip.startTime)) && dayjs(dayjs()).isBefore(dayjs(trip.endTime)) ?
                <Pill color="accent">
                  {t("trip.inProgress")}
                </Pill>
                : null}
              <div><i className="icon-calendar leading" />{dayjs(trip.startTime).format(t("dateFormatting.day"))}</div>
              <div><i className="icon-time leading" />{dayjs(trip.startTime).format("HH:mm")}</div>
            </CardMain>
          </Card>
        })
        }
      </ContextMenu>
    </>
  )
}