import { Icon } from '@o4c/plugin-components';
import { forwardRef } from 'react';
import './header.scss';

interface IHeaderProps {
  title: string;
  date: string;
  location: string;
  onClick: Function;
  optionalClass?: string;
}

export const Header = forwardRef<HTMLDivElement, IHeaderProps>((props, ref) => {
  return (
    <div ref={ref} className={`screen-header ${props.optionalClass}`} onClick={() => props.onClick()}>
      <div className="text">
        <span className="subtitle">{props.title}</span>
        {props.date}
        <div className="location">
          <i className="icon-location leading" />
          <span className="caption-text">{props.location}</span>
        </div>
      </div>
      <Icon name="arrow-down" />
    </div>
  )
})